<template>
<main>
  <section class="section">
    <div>
      <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
          <sqr-crumb name="relations" :bid="bid" label="breadcrumb_relations"/>
          <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation', relation)}}</sqr-crumb>
          <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation_coordinates', relation)}}</sqr-crumb>
        </ul>
      </nav>
    </div>
  </section>
  <section class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-4">
            <div class="field">
              <sqr-input-text
                label="relation_coordinates_telephone"
                :value="relation.telephone"
                @change="fieldSet({field: 'telephone', value: $event})"
              />
            </div>
            <div class="field">
              <sqr-input-text
                label="relation_coordinates_telephone_mobile"
                :value="relation.telephoneMobile"
                @change="fieldSet({field: 'telephoneMobile', value: $event})"
              />
            </div>
            <div class="field">
              <sqr-input-text
                label="relation_coordinates_telephone_work"
                :value="relation.telephoneWork"
                @change="fieldSet({field: 'telephoneWork', value: $event})"
              />
            </div>
            <div class="field">
              <sqr-input-text
                label="relation_coordinates_fax_number"
                :value="relation.faxNumber"
                @change="fieldSet({field: 'faxNumber', value: $event})"
              />
            </div>
            <div class="field">
              <sqr-input-text
                label="relation_coordinates_email"
                :value="relation.email"
                @change="fieldSet({field: 'email', value: $event})"
              />
            </div>
            <sqr-error-note :error="sendError"/>
            <div class="field">
              <div class="buttons is-pulled-right">
                <sqr-button label="buttons_cancel" @click="$router.back()" color="light" size="medium"/>
                <sqr-button label="buttons_save" @click="saveAndGo()" color="primary" size="medium" :is-loading="saving"/>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
</main>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';

import relation from './relation';

import SqrButton from '../sqrd/SqrButton';
import SqrInputText from '../sqrd/SqrInputText';
import SqrInputDate from '../sqrd/SqrInputDate';

export default {
  name: 'RelationCoordinates',
  mixins: [relation],
  components: {SqrInputText, SqrInputDate},
  props: {
    bid: String,
    id: String
  },
  computed: {
    ...mapGetters('relation', ['loading', 'loadError', 'updates', 'sending', 'sendError']),
    title () {
      if (!this.relation) return '';
      return this.$t('relation_title', Object.assign({}, this.relation, {
        status: this.$t('relation_status_' + this.relation.status)
      }));
    },
    subtitle () {
      if (!this.relation || !this.relation.birthDate) return '';
      return this.$t('relation_subtitle', {birthDate: date(this.relation.birthDate)});
    }
  },
  mounted () {
    this.sub({path: ['brokers', this.bid], id: this.id});
  },
  methods: {
    ...mapMutations('relation', ['fieldSet']),
    ...mapActions('relation', ['sub', 'send']),
    saveAndGo () {
      this.send().then(() => this.$router.back());
    }
  },
  metaInfo () {
    return {
      title: this.title
    }
  }
}
</script>

