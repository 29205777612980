<template>
  <div>
    <label class="label" :class="labelClass" v-if="labelTr">{{labelTr}}</label>
    <div class="control" :class="controlClass">
      <input
        :id="id"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholderTr"
        class="input"
        :class="inputClass"
        type="date"
        :aria-describedby="nameDesc"
        :value="value"
        :min="min"
        @input="input"
        @change="change"
        :disabled="disabled"
        v-on="listeners"
      >
      <span class="icon is-small is-left" v-if="icon"><i class="fa" :class="iconClass"></i></span>
      <span class="icon is-small is-right" v-if="valid"><i class="fal fa-check"></i></span>
    </div>
    <p class="help" v-if="helper">{{helperTr}}</p>
  </div>
</template>

<script>
import input from "./mixins/input";

export default {
  name: "sqr-input-date",
  mixins: [input]
};
</script>
